@keyframes FadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 0;
    animation: FadeIn 1s ease-in-out 1s forwards;
}

@keyframes Rotate {
    to {
        transform: rotate(360deg);
    }
}

.rotate {
    animation: Rotate 1s ease infinite forwards;
}

@keyframes fadeInTab {
    from {
        opacity: 0;
        transform: translateY(-4px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutTab {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-4px);
    }
}

.tab-fade-enter {
    animation: fadeInTab 0.2s ease-out forwards;
}

.tab-fade-exit {
    animation: fadeOutTab 0.2s ease-out forwards;
}

.tab-indicator {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
